import { User } from '../../Types/User'
import { axiosInstance } from '../Axios'

export const login = async ({ email, password }: { email: string, password: string }) => {
    const { data } = await axiosInstance.post<User>('/api/login', { email, password })
    return data
}

export const logout = async () => {
    const { data } = await axiosInstance.post<User>('/api/logout')
    return data
}

export const authUser = async () => {
    try {
        const { data } = await axiosInstance.get<User>('/api/user')
        return data
    } catch (error) {
        return undefined
    }
}
